import Vivus from 'vivus';
import Swiper from 'swiper';
import SwiperCore, {
    Navigation,
    Pagination,
    Scrollbar,
    EffectFade,
    Lazy
} from 'swiper/core';
SwiperCore.use([Navigation, Pagination, Scrollbar, Lazy, EffectFade]);
const slider = () => {
        const headerGallery = new Swiper('.headertop__swiper', {
            preloadImages: false,
            grabCursor: true,
            effect: 'fade',
            // fadeEffect: {
            //     crossFade: true
            // },
            loop: true,
            speed: 1000,
            autoplay: {
                reverseDirection: false,
                delay: 1500,
            },
            slidesPerView: 1,
        });
        const headerGalleryContainer = document.querySelector('.headertop__swiper')
        headerGalleryContainer.addEventListener('mouseenter', () => {
            headerGallery.autoplay.stop()
        })
        headerGalleryContainer.addEventListener('mouseleave', () => {
            headerGallery.autoplay.start()
        })
        const mainGallery = new Swiper('.gallery__swiper', {
                preloadImages: false,
                lazy: true,
                grabCursor: true,
                loop: false,
                spaceBetween: 30,
                    speed: 1000,
                    slidesPerView: 3,
                    scrollbar: {
                        el: '.gallery__scrollbar',
                    },
                    autoplay: {
                        reverseDirection: false,
                        delay: 1500,
                    },
                    breakpoints: {
                        320: {
                            slidesPerView: 1
                        },
                        496: {
                            slidesPerView: 2
                        },
                        998: {
                            slidesPerView: 3
                        }

                    }
                });
                const mainGalleryContainer = document.querySelector('.gallery__swiper')
                mainGalleryContainer.addEventListener('mouseenter', () => {
                    mainGallery.autoplay.stop()
                })
                mainGalleryContainer.addEventListener('mouseleave', () => {
                    mainGallery.autoplay.start()
                })

            const collage = new Swiper('.collage__swiper', {
                loop: false,
                speed: 1500,
                effect: "fade",
                navigation: {
                nextEl: '.collage__swiper-button-next',
                prevEl: '.collage__swiper-button-prev',
                },
            });
            const reviews = new Swiper('.reviews__swiper', {
                loop: false,
                speed: 1500,
                slidesPerView: 3,
                spaceBetween: 48,
                centeredSlides: true,
                pagination: {
                    el: ".reviews__pagination",
                    clickable: true,
                  },
                breakpoints: {
                    240: {
                    slidesPerView: 'auto',

                    },
                    518: {
                        slidesPerView: 2,

                        },
                        833: {
                            slidesPerView: 3,

                            },
                }
            });
                    const thumbs = document.querySelectorAll('#thumbs')
                    thumbs.forEach(thumb => {
                        new Vivus(
                            thumb,
                            {
                            type: 'delayed',
                            duration: 400,
                            animTimingFunction: Vivus.EASE
                            },
                        );
                    })
        }
        export default slider